.homeSection{
    width: 100%;
    min-height: calc(100vh - 87px) ;
    background-color: #7ec1ff1a;
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column !important;
    row-gap: 20px;
}
.homeSectionRow{
    display: flex;
}

.homeSectionRowTablet{
    color:black ;
    font-size:60px;
}

.homeSectionRowParagraph{
    font-size: 40px;
    font-weight: 590;
}

.homeSectionRowTextDev
{
    flex-direction: column;
}
.homeSectionRowText
{
    text-align: center;
}
.homeSectionRowIcon
{
    gap: 20px;
    font-size: 26px;
    color:#0088CC;
}



.homeSectionRowButton{
  border-radius: 18px !important;
  background-color: #0088CC !important;
  color: white !important;
  border: none !important;
  font-size: 12px !important;
  display: flex !important; 
  justify-content: center !important;
  align-items: center !important;
}

.anttypography{
    color: rgb(255, 255, 255) !important;
}

@media screen and (max-width: 992px) {
    .homeSection
    {    
        min-height: calc(50vh - 87px) ;
    }
    .homeSectionRow{
        margin-bottom: 20px;
    }
}
  
  