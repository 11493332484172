.HowWorkSectionRowFirst {
  margin-top: 70px;
  display: flex;
}
.HowWorkSectionRowOdd
{
  width: 100%;
  display: flex;
}
.HowWorkSectionRow {
  display: flex;
  width: 100%;
}

.HowWorKSectionImage{
    display: flex;
    justify-content: center;
}


.HowWorkSectionRowParagraph {
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column !important;
  justify-content: center;
}

.HowWorkSectionRowParagraphHeader {
  margin-top: 10px;
  font-size: 32px;
  font-weight: 600;
  color: #0088CC;
}

.HowWorkSectionRowParagraph {
  margin-top: 0px;
}

.HowWorkSectionRowButton,
.HowWorkSectionRowButton:hover,
.HowWorkSectionRowButton:focus {
  margin-top: 25px;
  font-size: 14px;
  background-color: white;
  color: #0088CC;
  border-radius: 20px;
}


.HeaderSectionParagraph {
  font-size: 25px;
  font-weight: 600;
  font-family: "Cairo";
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0;
  color: #0088CC;
}

@media screen and (max-width: 992px) {
  .HowWorkSectionRowOdd{
   display: flex;
  }
  .HowWorkSectionRow{
    display: flex;
    flex-flow:wrap-reverse;
  }
}

@media screen and (min-width: 992px) {
  .HeaderSectionParagraph {
  margin-bottom: 4rem;
  }

  .HowWorkSectionRowParagraph{    
      font-size: 18px;
  }

}

