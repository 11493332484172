



@media screen and (max-width: 633px) {
    .ourVision{
        display: none;
    }
  
}

@media screen and (max-width: 518px) {
    .ourVision{
        display: none;
    }
    .ourGoal{
        display: none;
    }
    .mrsService{
        display: none;
    }
  
}

@media screen and (max-width: 444px) {
    .ourVision{
        display: none;
    }
    .ourGoal{
        display: none;
    }
    .mrsService{
        display: none;
    }
  
}

@media screen and (max-width: 370px) {
    .ourVision{
        display: none;
    }
    .ourGoal{
        display: none;
    }
    .whoWeAre{
        display: none;
    }
    .mrsService{
        display: none;
    }
    .features{
        padding-left: 10px;
    }
}



/* .features {
    display: none;
  } */