.navbarRow {
  width: 100%;
  background-color: white;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.navbarLink {
  flex-direction: row;
  justify-content: space-between;
  font-weight: bolder;
}


.navbarLink {
  display: flex !important;
}
.navbarContact {
  display: flex !important;
  flex-direction: row;
  justify-content: flex-end;
  gap: 20px;
  align-items: center;
}
.navbarLinkPharagraph {
  height: 87px;
  min-width: 74px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navbar {
  display: flex;
  gap: 50px;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
  padding-bottom: 5px;
  max-width: 100% !important;
  flex-wrap: wrap;
}
.navbarContactButton,
.navbarContactButton:hover,
.navbarContactButton:focus {
  border-radius: 6px;
  background-color: #0088cc !important;
  color: white !important;
  border: none;
  font-size: 12px;
  width: 94px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.navbarChangeLanguageButton{
  border-radius: 6px !important;
  background-color: #0088cc !important;
  color: white !important;
  font-size: 13px !important;;
  width: 60px !important;;
  height: 40px !important;;
  padding: 5px !important;;
  display: flex !important;;
  justify-content: center !important;;
  align-items: center !important;;
}
.navbarRowImage {
  padding: 0px 6px;
}
.anttypography {
  color: black !important;
}

@media screen and (max-width: 992px) {
  .navbarRow {
    justify-content: center;
    flex-direction: column-reverse !important;
    row-gap: 15px;
  }
  .navbarRowImage {
    height: 50px !important;
  }
  .navbarLinkPharagraph {
    height: 50px !important;
  }
  .navbarContact {
    height: 50px !important;
    margin-bottom: 10px;
    align-items: center;
    display:flex;
    flex-direction: row;
    gap: 15px;

  }
}



