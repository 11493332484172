.OurGoalSection {
    width: 100%;
    min-height: 40%;
}

.MarginWhoAreWeSection {
    margin-bottom: 60px;
}

.HeaderSectionContainer {
    width: 100%;
    display: flex;
    align-items: center;
}

.HeaderSectionParagraph {
    font-size: 25px;
    font-weight: 600;
    font-family: "Cairo";
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0;
}

.HeaderSectionContent {
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    text-align: right;
}
.Paragraph{    
    font-size: 14px;
}
.HeaderSectionParagraph {
    font-size: 25px;
    font-weight: 600;
    font-family: "Cairo";
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 0;
    color: #0088CC;
}
.ImageContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 992px) {
    .Paragraph{    
        font-size: 14px;
        text-align: center;
        padding-top: 20px;
        padding-left: 20px;
        padding-right: 20px;
    }
    .HeaderSectionContainer {
        width: 100%;
        display: flex;
        align-items: center;
        row-gap: 20px;
    }

}

@media screen and (min-width: 992px) {
    .HeaderSectionParagraph {
    margin-bottom: 4rem;
    }

    .Paragraph{    
        font-size: 18px;
    }

}
  