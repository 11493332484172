@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600&display=swap');

.App {
  text-align: center;
}

.font{
  font-family:'Cairo';
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.App-Container {
  margin: auto;
 
}










@media (min-width: 576px) {
  .App-Container {
   width: 550px !important;
  }
}


@media (min-width: 768px) {
  .App-Container {
   width: 750px !important;
  }
}




@media (min-width: 992px) {
  .App-Container {
   width: 970px !important;
   padding-top: 2rem;
   padding-bottom: 2rem;
  }
}


@media (min-width: 1200px) {
  .App-Container {
   width: 1170px !important;
   margin: auto;
  }
}
p{
  margin: 0px;
  margin-bottom:  0px !important;
}
h1 {
  margin: 0px;
}
