.FooterContainer {
  min-height: 400px;
  background-color: #fcfdff;
}
.FooterSection {
  padding-top: 50px;
  display: flex;
  align-items: start;
  align-content: space-between;
}
.FooterCol {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  font-family: "Cairo";
}
.FotColNavbar {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: center;
}

.FirstColParagraph {
  text-align: right;
  /* direction: rtl; */
}
.IconRow {
  font-size: 20px;
  gap: 5px;
}
.ColHeader {
  font-size: 18px;
  font-weight: bolder;
  color: #0088cc;
}
.anttypography {
  color: black !important;
}
.SecondFooterRow {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
}
.SecondFooterRowCol {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 992px) {
  .FotColNavbar {
    display: flex;
    flex-direction: row;
    column-gap: 50px;
    justify-content: center;
    align-items: center;
  }
  .FirstColParagraph {
    display: flex;
    justify-content: center;
  }
  .FooterSection {
    row-gap: 20px;
  }
}

.feedback {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  width: 100% !important;
  margin-top: 20 !important;
}

.landing-page {
  width: 108px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.25s ease-out 0s;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  word-spacing: 3px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  width: 100%;
  gap: 10px;
  margin-bottom: 20px;
}

.landing-page a {
  color: white;
}

.p {
  margin: 10px 0px !important;
}
