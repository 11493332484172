.ContactUsSection { 
  width: 100%;
  min-height: 40%;
  font-family: "Cairo";

}

.MarginContactUsSection {
  margin-bottom: 60px;
}

.HeaderSectionContainer {
  width: 100%;
  display: flex;
  gap: 40px;
  justify-content: center;
}

.HeaderSectionParagraph {
  font-size: 25px;
  font-weight: 600;
  font-family: "Cairo";
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0;
  color: #0088cc;
}
.FormSectionContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 50px;
  font-family: 'Cairo';
}

.HeaderSectionContent {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-content: center;
  text-align: right;
}
.Paragraph {
  font-size: 18px;
  font-family: "Cario";
}
.ContactFormButton,
.ContactFormButton:hover,
.ContactFormButton:focus {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 600;
  background-color: #3049f394;
  font-size: 15px;
  border: none;
  padding: 20px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.MapRow {
  height: 265px;
}
.Information {
  padding-top: 50px;
}

@media screen and (max-width: 776px) {
  .Information {
    padding-inline: 5rem;
    flex-direction: column;
    padding-top: 0px;
    margin-top: 10px;
    padding: 20px;
  }
  .FormSectionContent {
    padding-top: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
  .contact-left-content-p {
    text-align: center;
    width: 100%;
  }
 
}
