.FeatureSection {
  width: 100%;
  min-height: 40%;
}

.HeaderSectionContainer {
  width: 100%;
  display: flex;
  align-items: center;
}
.HeaderSectionParagraph {
  font-size: 25px;
  font-weight: 600;
  font-family: "Cairo";
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0;
}

.CardDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  row-gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}


@media screen and (min-width: 992px) {
  .CardDiv {
    justify-content: center;
    column-gap: 3rem;
  }
}


.ant-card-body {
  padding-right: 10px !important;
  padding-left: 10px !important;
}


.RowCardDiv {
  text-align: center;
}
.antcard {
  font-size: 16px;
  font-family: "Cairo";
  display: flex;
  row-gap: 10px;
  text-align: center;
  justify-content: center;
  align-items: center;
  height: 200px !important;
  width: 150px !important;
  border-radius: 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

}

.antCardParagraph {
  font-size: 14px;
  padding-top: 20px;
  font-weight: 600;
}

@media screen and (min-width: 992px) {
  .antCardParagraph {
    font-size: 13px;
  }
}

@media (max-width: 576px) {
  .antCardParagraph {
    font-size: 13px !important;
  padding-top: 20px;
  font-weight: 600;
  }
}


@media (max-width: 510px) {
  .antCardParagraph {
    font-size: 12px !important;
  padding-top: 20px;
  font-weight: 600;
  }
}

  @media (max-width: 430px) {
    .antCardParagraph {
      font-size: 11px !important;
    padding-top: 20px;
    font-weight: 600;
    }
  }

  @media (max-width: 400px) {
    .antCardParagraph {
      font-size: 10px !important;
    padding-top: 30px;
    font-weight: 600;
    }
  }

@media (max-width: 342px) {
  .antCardParagraph {
    font-size: 10px !important;
  padding-top: 20px;
  font-weight: 600;
  }
}


.HeaderSectionParagraph {
  font-size: 25px;
  font-weight: 600;
  font-family: "Cairo";
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 0;
  color: #0088cc;
}

@media (max-width: 776px) {
  .FeatureSection {
    padding-left: 10px;
    padding-right: 10px;
  }
}

